
import {computed, defineComponent, PropType, reactive, Ref, ref, UnwrapRef} from 'vue';
import { EditOutlined,PlusCircleOutlined,DeleteOutlined } from '@ant-design/icons-vue';
import deepClone from '@/utils/deepClone'
import {useRoute, useRouter} from "vue-router";
import useProgramChian from "@/hooks/approvalChian/useProgramChian";
import {Modal} from "ant-design-vue";
import * as API from "@/API/approvalChain"
import {getAllUsersByRole} from "@/API/approvalChain";
import {DataProps} from "@/views/User/ApprovalProcess/ApprovalChain/ProgramChain/type";
interface DataItem {
  key: string;
  approvalLevel: string;
  approvalChainRole: string[];
  approvalChainUser: string[];
  levelTimes: number;
}
export default defineComponent({
  name:'approvalChainTable',
  components: {
    //EditOutlined,
    PlusCircleOutlined,
    DeleteOutlined
  },
  props: {
    chainName: {
      type: String,
      default: "",
    },
    effectiveIndex: {
      type: Number,
      default: 0,
    }
  },
  setup(props) {
    console.log('props.chainName=====',props.chainName)
    const route = useRoute();
    const router = useRouter();
    //#region [导入hook-useProgramChian()相关]
    const {
      getApprovalChainDetails,
      dataSource,
      getAllRolesByuserName,
      entityOptions,
      userOptions,
      saveApprovalChain,
      editableFlag,
      mode
    } = useProgramChian();
    //# endregion
    const isQuerySelectRolesFlg = ref<boolean>(true)
    console.log('route.query==============',route.query)
    if(route.query && route.query.id && route.query.Totype==='edit'){
      console.log('进入查询详情接口！')
      //getApprovalChainDetails(route.query.id as string,props.effectiveIndex)
    }
    if(route.query.Totype && route.query.Totype == 'new'){
      console.log('进入新增审批流=====！')
      mode.value = 'save'
      editableFlag.value = true
      //#region [设置dataSource 15级空数据源]
      dataSource.value = [
        {
          key: '1',
          approvalLevel: 'Level1',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '2',
          approvalLevel: 'Level2',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '3',
          approvalLevel: 'Level3',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '4',
          approvalLevel: 'Level4',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '5',
          approvalLevel: 'Level5',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '6',
          approvalLevel: 'Level6',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '7',
          approvalLevel: 'Level7',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '8',
          approvalLevel: 'Level8',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '9',
          approvalLevel: 'Level9',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '10',
          approvalLevel: 'Level10',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '11',
          approvalLevel: 'Level11',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '12',
          approvalLevel: 'Level12',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '13',
          approvalLevel: 'Level13',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '14',
          approvalLevel: 'Level14',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
        {
          key: '15',
          approvalLevel: 'Level15',
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[['11','aa']],
          approvalChainUserSelectList: [['11','aa']],
          approvalChainUser: [['','']],
          levelTimes: 1
        },
      ]
      //#endregion
    }
    if(route.query.Totype && route.query.Totype == 'copy'){
      console.log('进入copy审批流=====',route.query)
      getApprovalChainDetails(route.query.oldBusinessId as string)
    }
    const columns = [
      {
        dataIndex: 'approvalLevel',
        slots: { title: 'customTitle', customRender: 'approvalLevel' },
      },
      {
        title: 'ApprovalRole',
        dataIndex: 'approvalRole',
        width: '35%',
        slots: { customRender: 'approvalRole' },
      },
      {
        title: 'User',
        dataIndex: 'user',
        width: '35%',
        slots: { customRender: 'user' },
      },
      {
        title: 'operation',
        dataIndex: 'operation',
        slots: { customRender: 'operation' },
      },
    ];
    const count = computed(() => dataSource.value.length + 1);
    const editableData: UnwrapRef<Record<string, DataItem>> = reactive({});
    const levelTimes = ref<number>(1)
    const entity = undefined
    const formatDate = (date: string) => {
      const d = new Date(date)
      let month = '' + (d.getMonth() + 1)
      let day = '' + d.getDate()
      const year = d.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2) day = '0' + day;
      return [year, month, day].join('/').concat(' 00:00:00');
    }
    const doEdit = () => {
      const effectiveFromFormatStr = formatDate(route.query.effectiveFromStr as string)
      console.log('格式化有效期结果=====',effectiveFromFormatStr)
      // API.editableCheckApprovalChain(route.query.bu as string,props.chainName as string,effectiveFromFormatStr).then((res: any)=>
      // {
      //   console.log('校验编辑审批流返回结果：',res)
      //   if(res.code !== '0'){
      //     Modal.error({
      //       title: 'Tips',
      //       content: res.message//res.data.message
      //     })
      //   }
      //   else{
      //     editableFlag.value = true
      //     mode.value = 'save'
      //   }
      // })
    }
    const save = (key: string) => {
      Object.assign(dataSource.value.filter(item => key === item.key)[0], editableData[key]);
      delete editableData[key];
    };
    const onDelete = (record: any,index: number) => {
      if(record.levelTimes == 1){
        dataSource.value = dataSource.value.filter(item => item.key !== record.key);
        dataSource.value.map((item,index) =>{
          item.key = index.toString()
          item.approvalLevel = 'Level'+`${index+1}`
        })
        console.log('dataSource===',dataSource)
      }
      else{
        record.approvalChainRole.splice(index,1)
        record.approvalChainUser.splice(index,1)
        record.levelTimes--
      }
    };
    const handleAdd = () => {
      if(dataSource.value.length == 15){
        Modal.error({
          title: 'Tips',
          content: 'Already reach the max level 15.'
        })
        return;
      }
      else{
        const newData = {
          key: `${count.value}`,
          approvalLevel: `Level ${count.value}`,
          approvalChainRole: [['','']],
          approvalChainRoleSelectList:[],
          approvalChainUserSelectList: [],
          approvalChainUser: [['','']],
          levelTimes: 1
        };
        dataSource.value.push(newData);
      }
    };
    const handleAddRoleAndUser = (record: any,index: number) => {
      console.log('record====',record)
      record.approvalChainRole.push(['',''])
      record.approvalChainUser.push(['',''])
      record.levelTimes++
    }
    const tabVisable = ref<boolean>(false)
    const ntimes = ref<number>(0)
    const changeRole = (user: any,index: any,record: any) => {
      //const role = entityOptions.value.find((item: any) => item[0] === user)
      //record.approvalChainRole[index][1] = role[1]
      isQuerySelectRolesFlg.value = false
      API.getAllUsersByRole(user,route.query.bu as string).then((res: any)=>{
        console.log('getAllUsersByRole接口返回结果====',res)
        record.approvalChainUser[index] = res[0]
        console.log('更改后的datasource======',dataSource.value)
      })
    }
    const selectRoles = (value: any,index: any,key: any) => {
      console.log('点击seleceRoles传参：')
      if(route.query.Totype == 'new' && isQuerySelectRolesFlg){
        console.log('是否查询下拉role==========')
        getAllRolesByuserName("")
      }
    }
    const selectUsers = (roleId: any,index: any,key: any) => {
      if(route.query.Totype == 'new' && isQuerySelectRolesFlg){
        console.log('roleId===',roleId)
        API.getAllUsersByRole(roleId,"").then((res: any)=>{
          console.log('返回users下拉框的值：',res)
        })
      }
    }
    const doSave = ()=> {//bu: string,chainName: string,effectiveFrom: string,businessId: string
      saveApprovalChain(route.query.bu as string,props.chainName as string,route.query.effectiveFromStr as string,route.query.businessId as string)
    }
    return {
      columns,
      onDelete,
      handleAdd,
      handleAddRoleAndUser,
      dataSource,
      editableData,
      editableFlag,
      entity,
      entityOptions,
      levelTimes,
      count,
      doEdit,
      isQuerySelectRolesFlg,
      save,
      mode,
      tabVisable,
      ntimes,
      changeRole,
      selectRoles,
      doSave,
      selectUsers
    };
  },
});
